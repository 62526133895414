import { useState } from 'react'
import { login } from './util/firebase'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [notice, setNotice] = useState('')

    async function loginWithUsernameAndPassword(e) {
        e.preventDefault()
        try {
            await login(email, password)
            setNotice('')
        } catch(error) {
            console.error(error)
            setNotice('You entered a wrong username or password.')
        }
    }

    return(
        <div className = "container">
            <div className = "row justify-content-center">
                <form className = "col-md-5 mt-3 pt-3 pb-3">
                    <h3>Registration Manager</h3>
                    <p>Login is only for Registration Managers.<br/>
                        You don't login to submit a registration form.
                    </p>
                    { notice &&
                        <div className = "alert alert-warning" role = "alert">
                            { notice }    
                        </div>
                    }                  
                    <div className = "form-floating mb-3">
                        <input type = "email" className = "form-control" id = "exampleInputEmail1" aria-describedby = "emailHelp" placeholder = "name@example.com" value = { email } onChange = { (e) => setEmail(e.target.value) }></input>
                        <label htmlFor = "exampleInputEmail1" className = "form-label">Email address</label>
                    </div>
                    <div className = "form-floating mb-3">
                        <input type = "password" className = "form-control" id = "exampleInputPassword1" placeholder = "Password" value = { password } onChange = { (e) => setPassword(e.target.value) }></input>
                        <label htmlFor = "exampleInputPassword1" className = "form-label">Password</label>
                    </div>
                    <div className = "d-grid">
                        <button type='submit' className = "btn btn-primary" onClick = {(e) => loginWithUsernameAndPassword(e)}>Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
