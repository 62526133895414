import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstitution, faChevronRight, faCertificate } from '@fortawesome/free-solid-svg-icons'

import { getResource } from './util/resource'


function EventItem({ event }) {
    return (
        <Link to={`/register/${event.host_id}/${event.id}`} key={event.id}>
            <ListGroupItem>
                <FontAwesomeIcon icon={faChevronRight} className='fa fa-2x float-right'/>
                <FontAwesomeIcon icon={faCertificate} className='fa'/> { event.name }<br/>
                    { event.start_time.toDateString() }
                    { event.start_time.toDateString() !== event.stop_time.toDateString() &&
                    <span>&mdash; { event.stop_time.toDateString() }</span> }
                { event.info && <p>{ event.event_info }</p> }
            </ListGroupItem>
        </Link>
    )
}

const heading = {
    color: '#31708f',
    backgroundColor: '#d9edf7',
    borderColor: '#bce8f1'
}

export default function Host() {
    const { host_id } = useParams()
    const [host, setHost] = useState({})
    const [events, setEvents] = useState([])
    function getHost() {
        getResource('/host/'+host_id).then(setHost)
        getResource('/event/'+host_id).then( events => {
            events.forEach(event => {
                event.start_time = new Date(event.start_time)
                event.stop_time = new Date(event.stop_time)
            })
            setEvents(events)
        } )
    }
    useEffect(getHost, [host_id])

    const now = new Date()
    const current = [], previous = []
    events.forEach(event => {
        if (event.stop_time > now) current.push(event)
        else previous.push(event)
    })  // sort by -stop_time
    current.sort((a, b) => b.stop_time - a.stop_time)
    previous.sort((a, b) => b.stop_time - a.stop_time)

    return (
        <Container fluid>
            { (host.is_manager || host.is_admin) &&
            <p>
                <a href={`/manager/host/${ host_id }`}>
                    <Button>
                        <FontAwesomeIcon icon={faInstitution} className='fa'/> Host settings
                    </Button>
                </a>
            </p> }

            <Row>
                <Col sm={8}>
                    <h2>{ host.name }</h2>

                    { current.length > 0 &&
                    <Card className='mb-4'>
                        <Card.Header style={heading}>Current events</Card.Header>
                        <Card.Body>
                            <ListGroup>
                                { current.map(event => <EventItem event={event} key={event.id} /> ) }
                            </ListGroup>
                        </Card.Body>
                    </Card> }

                    { previous.length > 0 &&
                    <Card>
                        <Card.Header style={heading}>Previous events</Card.Header>
                        <ListGroup>
                            { previous.map(event => <EventItem event={event} key={event.id} /> ) }
                        </ListGroup>
                    </Card> }

                </Col>
                <Col sm={4}>
                    <Image src={ host.logo } thumbnail />
                </Col>
            </Row>
        </Container>
    )
}
