import React, { useState, useEffect } from 'react'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstitution, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'

import { getResource, postResource } from './util/resource'


const FormGroupClass = 'mb-4 ms-4 me-4'

export default function HostRequest() {
    const [errors, setErrors] = useState({})
    const [text, setText] = useState({})
    useEffect(() => { getResource('/text/Terms of Service').then(setText) }, [])
    const [requested, setRequested] = useState({})
    useEffect(() => { getResource('/text/Host requested').then(setRequested) }, [])
    const [path, setPath] = useState('path')
    const [submitted, setSubmitted] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState()

    function validatePath(path) {
        let validated = false
        if (!path.match(/^[a-z\d_-]+$/))
            errors.path = 'Must be lower-case letters, digits, - or _'
        else {
            validated = true
            delete errors.path
            setPath(path)
        }
        setErrors({...errors})
        return validated
    }

    async function submitRequest(form_event) {
        form_event.preventDefault()
        const form = form_event.currentTarget
        if (!form.checkValidity()) return
        const formData = new FormData(form),
                formDataObj = Object.fromEntries(formData.entries())
        //console.log('formData', formData)
        if (!validatePath(formDataObj.path)) return
        setSubmitted(true)
        const res = await postResource('/host/', formDataObj)
        //console.log('res', JSON.stringify(res))
        setSubmitted(false)
        if (res.status === 'error') {
            //console.error(res.property, res.message)
            setErrors({[res.property]: res.message})
            // scroll up to the field with error
            document.getElementById(res.property).scrollIntoView({ behavior: 'smooth' })
        }
        if (res.status === 'ok') {
            form.reset()
            setErrors({})
            setMessageSuccess(requested.content)
        }
    }

    if (messageSuccess) {
        return (
            <Container>
                <Alert variant='success'>
                    <div dangerouslySetInnerHTML={{__html: messageSuccess}}/>
                </Alert>
            </Container>
        )
    }

    return (
    <Container>
        <Form className='well mt-5 p-4' onSubmit={submitRequest}>
            <legend>
                <FontAwesomeIcon icon={faInstitution} className='fa'/> Host Request
            </legend>
            <p>
                Register your organization to be a Host and sell registrations on eRegister Express.
            </p>
            <p>Fill in all the fields below:</p>

            <hr/>
            <h5>Public Info</h5><br/>
            <Form.Group className={FormGroupClass} controlId='name' id='name'>
                <div>The name shown on all your events and registration forms</div>
                <FloatingLabel label='Organization Name'>
                    <Form.Control name='name' type='text' isInvalid={!!errors.name} required
                                placeholder='Organization Name' />
                </FloatingLabel>
                {errors.name && <div style={{color: 'red'}}>{errors.name}</div> }
            </Form.Group>
            <Form.Group className={FormGroupClass} controlId='path' id='path'>
                <div>For user-friendly URLs to your events: /register/<u>{path}</u></div>
                <FloatingLabel label='URL Path'>
                    <Form.Control name='path' type='text' isInvalid={!!errors.path} required
                                    placeholder='URL Path'
                                    onChange={e => validatePath(e.currentTarget.value)}/>
                </FloatingLabel>
                {errors.path && <div style={{color: 'red'}}>{errors.path}</div> }
            </Form.Group>

            <hr/>
            <h5>Contact Info <small>(This is private and only used by eRegister Express)</small></h5><br/>
            <Form.Group className={FormGroupClass} controlId='phone' id='phone'>
                <div>In case eRegister Express needs to contact this organization</div>
                <FloatingLabel label='Organization Phone Number'>
                    <Form.Control name='phone' type='phone' isInvalid={!!errors.phone} required
                                    placeholder='Organization Phone Number' />
                </FloatingLabel>
                {errors.phone && <div style={{color: 'red'}}>{errors.phone}</div> }
            </Form.Group>
            <Form.Group className={FormGroupClass} controlId='address' id='address'>
                <div>Other information may be sent by eRegister Express to this address</div>
                <FloatingLabel label='Organization Address'>
                    <Form.Control name='address' as='textarea' rows={3} style={{height:90}}
                                    isInvalid={!!errors.address} required
                                    placeholder='Organization Address' />
                </FloatingLabel>
                {errors.address && <div style={{color: 'red'}}>{errors.address}</div> }
            </Form.Group>

            <hr/>
            <h5>Manager Info</h5><br/>
            <Form.Group className={FormGroupClass} controlId='manager_name' id='manager_name'>
                <div>The person who will be managing events on this site</div>
                <FloatingLabel label="Manager's name">
                    <Form.Control name='manager_name' type='text' isInvalid={!!errors.manager_name} required
                                    placeholder="Manager's name" />
                </FloatingLabel>
                {errors.manager_name && <div style={{color: 'red'}}>{errors.manager_name}</div> }
            </Form.Group>
            <Form.Group className={FormGroupClass} controlId='manager_email' id='manager_email'>
                <div>A confirmation will be sent to this email address</div>
                <FloatingLabel label="Manager's email address">
                    <Form.Control name='manager_email' type='text' isInvalid={!!errors.manager_email} required
                                    placeholder="Manager's email address" />
                </FloatingLabel>
                {errors.manager_email && <div style={{color: 'red'}}>{errors.manager_email}</div> }
            </Form.Group>
            <Form.Group className={FormGroupClass} controlId='manager_phone' id='manager_phone'>
                <div>In case we have any questions for the Manager</div>
                <FloatingLabel label="Manager's phone number">
                    <Form.Control name='manager_phone' type='phone' isInvalid={!!errors.manager_phone} required
                                    placeholder="Manager's phone number" />
                </FloatingLabel>
                {errors.manager_phone && <div style={{color: 'red'}}>{errors.manager_phone}</div> }
            </Form.Group>

            <hr/>
            <div className='well p-3 mb-3' style={{ backgroundColor: 'white', height: 180, overflow: 'auto' }}
                 dangerouslySetInnerHTML={{__html: text.content}} />
            <p>
                By clicking the "Sign Us Up!" button below, Host Organization represents that they have read the agreement
                and understand that the agreement will have the same legal effect and force as a signed document.
            </p>
            <Form.Group as={Row} className='mb-3' controlId='agree'>
                <Col sm={{ offset: 1, span: 11 }}>
                    <Form.Check name='agree' required label='Yes, I have read, understand and agree to the Terms of Service above.' />
                </Col>
            </Form.Group>

            <div className='ms-5'>
                <Button type='submit' disabled={submitted}>
                    { submitted
                        ? <Spinner animation='grow' className='fa' size='sm'/>
                        : <FontAwesomeIcon icon={faShareFromSquare} className='fa'/> }
                    &nbsp;Sign us up!
                </Button>
                <span> You must fill in the entire form before you can sign up.</span>
            </div>
        </Form>
    </Container>
    )
}