/*
When a customer completes a Stripe payment, they are sent here.
https://stripe.com/docs/payments/checkout/custom-success-page
*/

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { getResource } from './util/resource'

export default function Receipt() {
    const { form_id } = useParams()
    const [form, setForm] = useState()
    useEffect(() => { getResource('/form/'+form_id).then(setForm) }, [form_id])

    if (form?.status === 'error')
        return <h3>Form not found</h3>

    return (
        <Container fluid className='text-center'>
            <Card style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}>
                <Card.Header><br/><h2>Thank You</h2><p>{ form?.member_id }</p></Card.Header>
                <Card.Body className='pt-4'>
                    <p><FontAwesomeIcon icon={faCheckCircle} className='fa-9x' style={{ color: '#39b549' }}/></p>
                    <h2>Payment Successful!</h2>
                </Card.Body>
                <Card.Footer className='pt-4 pt-3'>
                    <p>You have been successfully charged for this transaction.</p>
                    <p>A confirmation has been sent to your email.</p>
                </Card.Footer>
            </Card>
        </Container>
    )
}
