import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'

import { getResource } from './util/resource'
import RegisterForm from './RegisterForm'

function update(event) {     // update event.register.*
    event.register = {total: event.base_price, add_late_fee: false, required: false,
                        service_fee: 0, plaid_fee: 0}
    event.form.forEach(field => {
        if (field.required && !field.value) event.register.required = true
        if (Number.isInteger(field.subtotal)) {
            event.register.total += field.subtotal
            if (field.add_late_fee)
                event.register.add_late_fee = true
            else if (field.type.startsWith('select') && field.value &&
                    field.select[field.value].add_late_fee)
                event.register.add_late_fee = true
        }
    } )

    const now = new Date()

    if (event.register.add_late_fee && now > event.late_date)
        event.register.total += event.late_fee

    if (event.register.total) {
        if (event.add_fees) {
            const percent = (event.application_fee.percent + 2.9) / 100.0
            const cents = event.application_fee.cents + 30
            event.register.service_fee = Math.round((event.register.total + cents) / (1 - percent)) - event.register.total
        }

        if (event.plaid_fees) {
            let percent = 0.8 / 100.0
            let cents = event.plaid_fee.cents
            if (event.register.total > 62500) {
                percent = 0
                cents += 500
            }
            event.register.plaid_fee = (event.register.total + cents) / (1 - percent) - event.register.total
        }
    }
}

export default function Register() {
    const { host_id, event_id } = useParams()
    const [host, setHost] = useState()
    useEffect(() => { getResource(`/host/${host_id}`).then(setHost) }, [host_id])
    const [event, setEvent] = useState()
    function updateEvent() {
        update(event)
        setEvent({...event})
    }
    useEffect(() => { getResource(`/event/${host_id}/${event_id}`).then(event => {
        if (!event.form) return setEvent(event)
        event.reg_start_time = new Date(event.reg_start_time)
        event.reg_stop_time = new Date(event.reg_stop_time)
        event.late_date = new Date(event.late_date)
        event.form.forEach(field => {
            if (field.type === 'checkbox') { field.value = false; field.subtotal = 0 }
            if (field.select) { field.value=''; field.subtotal = 0 }
        } )
        update(event)
        setEvent({...event})
    } ) }, [host_id, event_id])

    const now = new Date()

    if (!host || !event) return (
        <Container fluid>
            <h3><Spinner animation="grow" className="fa mb-2" size="sm" /> Loading form</h3>
        </Container>
    )

    if (host.status === 'error') return (
        <Container fluid>
            <Alert variant='danger'>This host ({host_id}) does not exist</Alert>
        </Container>
    )

    if (event.status === 'error') return (
        <Container fluid>
            <Alert variant='danger'>This event ({event_id}) does not exist</Alert>
        </Container>
    )

    if (!host.is_active) return (
        <Container fluid>
            <Alert variant='danger'>This host is not yet active. eRegister Express must approve this host</Alert>
        </Container>
    )

    if (!host.has_stripe) return (
        <Container fluid>
            <Alert variant='danger'>Stripe payment information is missing from the Host Info page</Alert>
        </Container>
    )

    return (
        <Container fluid>
            { host.is_manager &&
            <div>
                <a href={`/manager/event/${ host_id }/${ event_id }`}>
                    <Button>Manager only: Event info</Button>
                </a>
            </div> }

                <Image src={event.image} style={{ float: 'right' }} height={80} />
                <h4>{ host.name }</h4>
                <h3>{ event.name }</h3>
                <br/>

                { !event.is_active && <Alert variant='warning'>
                    This event is not yet active.
                    A manager must mark this event as Active in the Event Info page
                </Alert> }
                { now < event.reg_start_time && <Alert variant='success'>
                    This event will open for registrations at { event.reg_start_time.toLocaleString() }
                </Alert> }
                { now > event.reg_stop_time && <Alert variant='danger'>
                    This event closed for registrations at { event.reg_stop_time.toLocaleString() }
                </Alert> }

                <RegisterForm event={event} updateEvent={updateEvent}/>
        </Container>
    )
}
