import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Container from 'react-bootstrap/Container'

import { getResource } from './util/resource'

const Names = {
    'our_services': 'Our Services',
    'us': 'About Us',
    'privacy': 'Privacy Policy',
    'terms': 'Terms of Service',
}


export default function About() {
    const { page } = useParams()
    const name = Names[page]
    const [text, setText] = useState()
    useEffect(() => { getResource('/text/'+name).then(setText) }, [name])
    return (
        <Container fluid>
            { text?.content && <div dangerouslySetInnerHTML={{__html: text.content}} /> }
        </Container>
    )
}
