import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'

import { postResource, deleteResource } from './resource'


const firebaseConfig = {
    apiKey: "AIzaSyDul_9M6gwvu_8Be5rRaZjTSVKqt1zcCJQ",
    authDomain: "eregexp-da0ba.firebaseapp.com",
    databaseURL: "https://eregexp-da0ba.firebaseio.com",
    projectId: "eregexp-da0ba",
    storageBucket: "eregexp-da0ba.appspot.com",
    messagingSenderId: "115401079475",
    appId: "1:115401079475:web:10fc38fc98909a3aa5cec6",
    measurementId: "G-LWTL2QGYQV"
}
  
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const analytics = getAnalytics(app)

async function login(email, password)
{
    return signInWithEmailAndPassword(auth, email, password)
}

function onAuthStateChange(callback) {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            const idToken = await user.getIdToken()
            const res = await postResource('/session', { idToken })
            if (res?.code === 'auth/invalid-id-token')
                user = null
            else if (window.location.pathname === '/login')
                window.location.assign('/manager')
        }
        callback(user)
    })
}

async function logout() {
    await signOut(auth)
    deleteResource('/session')
}

function log_event(event) {
    logEvent(analytics, event)
}

export { login, onAuthStateChange, logout, log_event }
