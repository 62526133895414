import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

import { postResource } from './util/resource'

const now = new Date()
const Required = { color: 'red' }

function dollars(amount) {
    return '$' + (amount / 100.0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

export default function RegisterForm({ event, updateEvent }) {
    const navigate = useNavigate()
    const [checkoutError, setCheckoutError] = useState()

    async function checkout() {
        const form = await postResource(`/register/${event.host_id}/${event.id}`, {form: event.form})
        if (form.status === 'ok')
            navigate('/checkout/'+form.form_id)
        else setCheckoutError(form)
    }

    if (!checkoutError) {   // show form but prevent Checkout for any of these conditions:
        if (!event.is_active)
            setCheckoutError({message:
                'This event is not yet active. The host must mark this event as Active in the Event Info page'
            })
        if (now < event.reg_start_time)
            setCheckoutError({message:
                `This event will open for registrations at ${ event.reg_start_time.toLocaleString() }`
            })
        if (now > event.reg_stop_time)
            setCheckoutError({message:
                `This event closed for registrations at ${ event.reg_stop_time.toLocaleString() }`
            })
    }

    return (
        <Card>
            <Card.Header className='pt-3'>
                <span className='mt-1' style={{ ...Required, float: 'right' }}>* Required fields</span>
                <h5>Register</h5>
            </Card.Header>
            <Card.Body>
        <Form>
            <div dangerouslySetInnerHTML={{ __html: event.instructions }} />

            <Row>
            { event.form.map((field, index) =>
            <Col xs={field.width === 'half' ? 6 : 12} key={index}>
                { field.type === 'header' && <>
                    <h3 className='mt-4'><i>{ field.name }</i></h3>
                    { field.help && <p className="help-block">{ field.help }</p> }
                    </>
                }

                { field.type === 'info' && <>
                    <Col xs={12} className='mt-3'><label><b>{ field.name }</b></label></Col> 
                    <Col xs={12} dangerouslySetInnerHTML={{ __html: field.info }} />
                </> }

                { field.type === 'checkbox' &&
                <Row>
                    { field.price > 0 &&
                    <Col md={1} xs={3} className='text-right'>{ dollars(field.subtotal) }</Col> }
                    <Col md={field.price > 0 ? 6 : 10} xs={field.price > 0 ? 7 : 10}>
                        <div className='form-check'>
                            <input type='checkbox' className='form-check-input'
                                checked={field.value}
                                style={{ borderColor: '#777' }}
                                onChange={e => {
                                    field.value = !field.value
                                    field.subtotal = field.value ? field.price : 0
                                    updateEvent()
                                    setCheckoutError(null)
                                } }
                            />
                            <label>{field.name} { field.required && <span style={Required}> *</span> }</label>
                        </div>
                        { field.help && <p className="help-block">{ field.help }</p> }
                    </Col>
                </Row> }

                { !['header', 'info', 'checkbox'].includes(field.type) &&
                <Row>
                    <Col xs={12} className='mt-3'>
                        <Form.Label>
                            { field.name }
                            { field.required && <span style={Required}> *</span> }
                        </Form.Label>
                    </Col>
                    { field.help && <Col xs={12} className='help-block mb-2'>{ field.help }</Col> }

                    { ['text', 'email', 'phone'].includes(field.type) &&
                        <Col sm={8} md={8} className='mb-3'>
                            <Form.Control type={field.type} required={field.required}
                                isInvalid={field.required && !field.value}
                                placeholder="Your answer" className='border-secondary'
                                onChange={e => {field.value = e.target.value; updateEvent(); setCheckoutError(null)}} />
                        </Col>
                    }

                    { field.type.startsWith('select') && <>
                        <Col xs={8} md={8}>
                            <Form.Select isInvalid={field.required && (field.value === '')}
                                className='border-secondary'
                                onChange={e => {
                                    field.value = e.target.value
                                    field.subtotal = (field.value === '') ? 0 : field.select[field.value].price
                                    updateEvent()
                                    setCheckoutError(null)
                                } } >
                                <option/>
                                { field.select.map((select, index) =>
                                    <option value={index} key={index}>{select.label}</option>
                                ) }
                            </Form.Select>
                        </Col>
                        {field.select.some(f => f.price > 0) &&
                        <Col xs={4} md={4} className='form-control-static text-right mt-2'>
                            { dollars(field.subtotal) }
                        </Col> }
                    </> }
                </Row> }
            </Col> ) }
            </Row><br/>

            <Table className='rounded border' style={{ maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                <tbody>
                    <tr><td colSpan={2} style={{ backgroundColor: '#eee'}} className='text-center'>Payment Items</td></tr>
                        { event.base_price !== 0 &&
                            <tr>
                                <td className='text-end'>Base price</td>
                                <td className='text-start'>{ dollars(event.base_price) }</td>
                            </tr> }
                        { event.form.filter(field => field.subtotal > 0).map(field =>
                            <tr key={field.id}><td className='text-end'>
                                { field.type.startsWith('select') ? field.select[field.value].label : field.name }</td>
                                <td className='text-start'>{ dollars(field.subtotal) }</td>
                            </tr>
                        ) }
                        { (event.register.add_late_fee && now > event.late_date && event.late_fee !== 0) &&
                            <tr>
                                <td className='text-end'>Late fee</td>
                                <td className='text-start'>{ dollars(event.late_fee) }</td>
                            </tr>
                        }
                        <tr>
                            <td className='text-end'><b>Amount:</b></td>
                            <td className='text-start'><b>{ dollars(event.register.total) }</b></td>
                        </tr>
                </tbody>
            </Table>

            <Form.Group className='text-center mt-3 mb-4'>
                <br/>
                { event.register.required &&
                <p><b>
                    You must fill in all the <span style={ Required }>* Required</span> fields
                </b></p> }
                <Button onClick={checkout} disabled={event.register.required || checkoutError}>
                    Proceed to Checkout</Button>
                { checkoutError && <Alert variant='danger' dismissable>
                    { checkoutError.message === 'not a prior member'
                    ? <div>
                        <p>Your returning member registration cannot be completed.</p>
                        <p>The email entered does not match a previous email.</p>
                        <p>Please check your email for typos.</p>
                        <p>If your email has changed, contact support@eregisterexpress.com
                            to update your email.</p>
                      </div>
                    : checkoutError.message }
                </Alert> }
            </Form.Group>
        </Form>
        </Card.Body>
        </Card>
    )
}
