import React, { useState, useEffect } from 'react'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-regular-svg-icons'

import { getResource, postResource } from './util/resource'


export default function Contact() {
    const sandbox_mode = (new URLSearchParams(window.location.search)).get('sandbox_mode')
    const [text, setText] = useState()
    useEffect(() => { getResource('/text/Contact Us').then(setText) }, [])
    const [message_success, setMessageSuccess] = useState()
    const [message_warning, setMessageWarning] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [errors, setErrors] = useState({})

    async function submit(event) {
        event.preventDefault()
        event.preventDefault()
        const form = event.currentTarget
        if (!form.checkValidity()) return
        const formData = new FormData(form),
                formDataObj = Object.fromEntries(formData.entries())
        if (sandbox_mode) formDataObj.sandbox_mode = true
        setSubmitting(true)
        let res
        try {
            res = await postResource('/email', formDataObj)
        } catch (err) { setMessageWarning('We were unable to send your message due to technical difficulties. Please try again later.') }
        setSubmitting(false)
        if (res.status === 'ok') {
            setMessageSuccess('Your message about "' + formDataObj.subject + '" has been sent.'
            + ' We will try to reply within two business days.')
            form.reset()
        }
        if (res.status === 'error') {
            setErrors({...errors, [res.property]: res.message})
        }
    }
  
    return (
        <Container fluid>
            { text &&
            <Alert variant='warning'><div dangerouslySetInnerHTML={{__html: text.content}} /></Alert> }
            { message_success && <Alert variant='success'>{ message_success }</Alert> }
            { message_warning && <Alert variant='warning'>{ message_warning }</Alert> }

            <Form className='well' style={{ padding: 16 }} onSubmit={submit}>
                <h4>
                    <FontAwesomeIcon className='fa' icon={faEnvelope} /> Contact Us
                </h4>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={2} className='label'>To:</Form.Label>
                    <Col sm={10}>
                        <Form.Control plaintext readOnly defaultValue='eRegister Express Customer Support'/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={2}>Your name:</Form.Label>
                    <Col sm={6}>
                        <Form.Control type='text' name='name' isInvalid={errors.name} required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={2}>Your email:</Form.Label>
                    <Col sm={6}>
                        <Form.Control type='email' name='email' isInvalid={errors.email} required/>
                        <Form.Control.Feedback type='invalid'>Please enter a valid email address</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={2}>Subject:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type='text' name='subject' isInvalid={errors.subject} required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={2}>Message:</Form.Label>
                    <Col sm={10}>
                        <Form.Control as='textarea' rows={3} type='text' name='message'
                                    isInvalid={errors.message} required/>
                    </Col>
                </Form.Group>
                <Row>
                    <Col sm={{ offset: 2, span: 10 }}>
                        <Button disabled={submitting} variant='outline-primary' type='submit'>
                            <FontAwesomeIcon className='fa' icon={submitting ? faEnvelopeOpen : faPaperPlane } /> Send
                        </Button>
                        &nbsp;All fields are required
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}