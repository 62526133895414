/*
After a customer completes a registration form and clicks Proceed to checkout,
they come to this page.
https://stripe.com/docs/connect/collect-then-transfer-guide?platform=web#accept-payment
*/

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

import { getResource, postResource } from './util/resource'

function dollars(amount) {
    return '$' + (amount / 100.0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

function Checkout() {
    const { form_id } = useParams()
    const [host, setHost] = useState()
    const [event, setEvent] = useState()
    const [form, setForm] = useState()
    const [error, setError] = useState()
    const [test_mode, setTest_Mode] = useState(false)
    useEffect(() => {
        async function get() {
            const form = await getResource('/form/'+form_id)
            await getResource('/host/'+form.host_id).then(setHost)
            await getResource(`/event/${form.host_id}/${form.event_id}`).then(setEvent)
            form.amount = 0
            for (const item of form.items)
                form.amount += item.price
            setForm(form)
        }
        get()
    }, [form_id])

    // When the user clicks on the Pay button for each method (Card, Bank),
    // create a Stripe session
    async function checkout(method) {
        setError(null)
        const href = window.location.href
        const session = await postResource('/stripe/session', {
            test_mode,
            form_id,
            method,
            cancel_url: href,
            success_url: href.replace('checkout', 'receipt')})
        if (session.status === 'error')
            setError(session.property + ': ' + session.message)
        if ( session.url )
            window.location = session.url
    }

    if (!form || !host || !event) return null

    if (form?.status === 'error')
        return <h3>Form not found</h3>

    const Paid = form.payment?.status === 'succeeded' ? true : false

    return (
        <Container fluid>
            <Image src={event.image} style={{ float: 'right' }} height={80} />
            <h4>{ host.name }</h4>
            <h3>{ event.name }</h3>

            <Card className='mt-5 mb-3'>
                <Card.Header className='pt-3'><h5>Checkout</h5></Card.Header>
                <Card.Body className='text-center'>
                    <Table className='rounded border' style={{ maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                        <tbody>
                        <tr><td colSpan={2} style={{ backgroundColor: '#eee'}}>Payment Items</td></tr>
                        { form.items.map((item, i) =>
                            <tr key={i}>
                                <td className='text-end'>{item.name}</td>
                                <td className='text-start'>{dollars(item.price)}</td>
                            </tr>
                        )}
                        <tr><td className='text-center' colSpan={2}><b>Amount: { dollars(form.amount) }</b></td></tr>
                        </tbody>
                    </Table>
                    { !Paid &&
                        <div>
                            <div className='mt-4 mb-1'>
                                Secure payments powered by
                                <img src='/stripe_logo.svg' alt='Stripe' width='60px'/>
                                <br/>Pay with Credit/Debit Card
                                { event.plaid_enabled && <> <b>-OR-</b> Bank ACH Direct Debit</>}
                            </div>
                            <Row>
                                <Col/>
                                <Col className='rounded border text-center pt-2 pb-3 ps-3 pe-3' xs='auto'>
                                    <p><img src='/stripe_cards.png' width='50px' alt='Card'/><br/>Card</p>
                                    <p>Fee { dollars(form.credit_card.amount - form.amount) }</p>
                                    <Button onClick={() => checkout('card')}>
                                        Pay { dollars(form.credit_card.amount) }
                                    </Button>
                                </Col>
                                <Col xs='auto'>  </Col>
                                { event.plaid_enabled &&
                                <Col className='rounded border text-center pt-2 pb-3 ps-3 pe-3' xs='auto'>
                                    <p><img src='/stripe_bank_transfer.png' width='50px' alt='Card'/><br/>Bank</p>
                                    <p>Fee { dollars(form.bank_transfer.amount - form.amount) }</p>
                                    <Button onClick={() => checkout('us_bank_account')}>
                                        Pay { dollars(form.bank_transfer.amount) }
                                    </Button>
                                </Col>
                                }
                                <Col/>
                            </Row>
                        </div>
                    }
                </Card.Body>
                <Card.Footer>
                    { Paid && <b>Already paid</b> }
                </Card.Footer>
            </Card>
            { error && <Alert variant='danger'>{error}</Alert> }

            { (event.is_manager || event.is_admin) &&
                <div>Manager: <Button onClick={() => checkout('offline')}>
                    Offline payment</Button>
                </div>
            }

            { event.is_admin && <div className='mt-3'>Admin: Stripe Test mode&nbsp;
                    <Button size='sm' variant={test_mode ? 'warning' : 'secondary'}
                            onClick={() => setTest_Mode(!test_mode)}>
                        {test_mode ? 'On' : 'Off'}</Button>
                </div> }
        </Container>
    )
}

export default Checkout