import React, { useState, useEffect } from 'react'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

import { postResource } from './util/resource'

export default function StripeConnect() {
    const query_params = new URLSearchParams(window.location.search)
    const code = query_params.get('code')
    const host_id = query_params.get('state')
    const test_mode = query_params.get('test_mode')
    const [res, setRes] = useState()

    useEffect(() => {
        async function StripeOauth() {
            setRes(await postResource('/stripe/oauth', {host_id, code, test_mode}))
        }
        StripeOauth()
    }, [host_id, code, test_mode])  // authorization code

    function Status(res) {
        if (!res) return <p><Spinner animation='grow' className='fa' size='sm'/> Updating your Stripe account</p>
        if (res.status === 'ok') return <p>You are now connected with Stripe</p>
        if (res.status === 'error') return <p>{ res.property }: { res.message }</p>
        if (res.status === 400) return <p>{ res.response.text }</p>
        return <p>res: {JSON.stringify(res, null, '  ')}</p>
    }

    return (
        <Container fluid>
            <h1>Stripe Connect</h1>
            <br/>
            { Status(res) }
            <div><a href={`/manager/host/${host_id}`}><Button>Return to the Host page</Button></a></div>
        </Container>
    )
}