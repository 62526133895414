import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faHeart, faKey, faFutbol, faStar, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons'

import { getResource } from './util/resource'

export default function Home() {
    const [events, setEvents] = useState([])
    useEffect(() => { getResource('/home').then(setEvents) }, [])
    const current = [], coming = [], now = new Date()
    events.forEach(event => {
        // event.description = $sce.trustAsHtml(event.description)
        event.start_time = new Date(event.start_time)
        event.stop_time = new Date(event.stop_time)
        event.on_sale_time = new Date(event.on_sale_time)
        if (event.on_sale_time > now)
            coming.push(event)
        else
            current.push(event)
    })
    current.sort((a, b) => a.host_name.localeCompare(b.host_name))
    coming.sort((a, b) => a.start_time - b.start_time)


    return (
    <Container fluid>
        <Row style={{ marginBottom: '20px' }}>
            <Col xs={9}>
                <h2 style={{ color: '#ff7d00', fontSize: 'xx-large', fontWeight: 'bold' }}>eRegister Express</h2>
                <i>The fastest way to manage event registrations</i>
            </Col>
            <Col xs={3}>
                <div className='blue-gradient' style={{ marginLeft: 'auto', width: 106, padding: 12 }}>
                    <FontAwesomeIcon className='fa logo' icon={faSquareCheck} />
                </div>
            </Col>
        </Row>

        { (current.length > 0) &&
        <div className='mb-3'>
            <h2 style={{ color: '#ff7d00' }}>On Sale Now!</h2>
            <Row style={{ rowGap: 24 }}>
            { current.map((event, e) =>
            <Col key={e}>
                <Link to={`/register/${event.host_id}/${event.id}`} style={{ textDecoration: 'none' }}>
                    <Card className='highlight_on_hover' style={{width: 192, height: '100%'}}>
                        <Card.Header style={{height: 140}}>
                            <Image src={event.image} className='mt-auto mb-auto'
                                style={{ width: '100%', maxHeight: 124 }} />
                        </Card.Header>
                        <Card.Body>
                            <p>{ event.host_name }</p>
                            <h5>{ event.name }</h5>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
            )}
            </Row>
        </div>
        }

        { (coming.length > 0) &&
        <div className='mb-3'>
            <h2 style={{ color: '#ff7d00' }}>Coming Soon!</h2>
            <Row>
            { coming.map((event, e) =>
            <Col key={e}>
                <Link to={`/register/${event.host_id}/${event.id}`} style={{ textDecoration: 'none' }}>
                    <Card className='highlight_on_hover' style={{width: 200, height: '100%'}}>
                        <Card.Header style={{height: 140}}>
                            <Image src={event.image} className='mt-auto mb-auto'
                                style={{ width: '100%', maxHeight: 124 }} />
                        </Card.Header>
                        <Card.Body>
                            <p>{ event.host_name }</p>
                            <h5>{ event.name }</h5>
                        </Card.Body>
                    </Card>
                </Link>
            </Col>
            )}
            </Row>
        </div>
        }

        <div>
            <p>eRegister Express allows schools and non-profit groups to create event registrations online</p>
        </div>

        <div style={{ backgroundColor: '#59bdff', marginTop: '20px', padding: '10px', borderRadius: '5px', fontSize: 12 }}>
            <div style={{ fontSize: 16 }}><b>Our Partners for registrations &amp; ticketing events:</b></div>
            <Row>
                <Col sm={4} className='mt-4'>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon className='fa' icon={faKey}/> Associations
                        </Card.Header>
                        <Card.Body>
                            <a href="https://www.eregisterexpress.com/host/alderpoint">Alder Point Club</a>
                            &nbsp;| <a href="https://www.eregisterexpress.com/host/bhea">Blue Heron Easement Association</a>
                            &nbsp;| <a href="https://www.eregisterexpress.com/host/fhea">Forest Hills Easement Association</a>
                            &nbsp;| <a href="https://www.eregisterexpress.com/host/lakeview">Lakeview Rec Club</a>
                            &nbsp;| <a href="https://www.eregisterexpress.com/host/lakewoodbaycc">Lakewood Bay Community Club</a>
                            &nbsp;| <a href="https://www.eregisterexpress.com/host/ppcc">Palisades Park Community Club Easement</a>
                            &nbsp;| <a href="https://www.eregisterexpress.com/host/uplands">Uplands Easement</a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4} className='mt-4'>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon className='fa' icon={faHeart}/> Non-profit Organizations
                        </Card.Header>
                        <Card.Body>
                            <a href="https://www.eticketexpress.com/host/familypromiseoftv">Family Promise of Tualatin Valley</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/lgpc">Lake Grove Presbyterian Church</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/mops">LGPC MOPS Club</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/5105803702829056">LO Schools Foundation</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/oll_parish">Our Lady of the Lake Parish</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/5631383682678784">Relay for Life</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/4767592208138240">Tiffany Autism Foundation</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/westlinnhistoricalsociety">West Linn Historical Society</a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4} className='mt-4'>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon className='fa' icon={faFutbol}/> Sports Clubs
                        </Card.Header>
                        <Card.Body>
                            <a href="https://www.eticketexpress.com/host/5752754626625536">Lake Oswego Community Rowing</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/lowpo">LO Water Polo Organization</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/manager/host/5744995038396416">Lakeridge Boys Lacrosse</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/5209171217612800">LOHS Swim Team</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/6034229603336192">LOHS Wrestling Team</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/5668753656250368">MAC Masters Swim Team</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/thills">Tualatin Hills Barracudas</a>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={4} className='mt-4'>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon className='fa' icon={faUniversalAccess}/> Parent Clubs
                        </Card.Header>
                        <Card.Body>
                            <a href="https://www.eregisterexpress.com/host/foresthillspto">Forest Hills PTO</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/lakerclub">Laker Club</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/oll_parish">OLL Parent Club</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/6200161848000512">Wilsonville HS Booster Club</a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4} className='mt-4'>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon className='fa' icon={faStar}/> Performing Arts Groups
                        </Card.Header>
                        <Card.Body>
                            <a href="https://www.eticketexpress.com/host/6475808612810752">Bridging Voices Youth Chorus</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/occyc">Oregon Children's Choir &amp; Youth Chorale</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/windjammers">Windjammers Choir</a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4} className='mt-3'>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon className='fa' icon={faGraduationCap}/> Schools
                        </Card.Header>
                        <Card.Body>
                            <a href="https://www.eticketexpress.com/host/atheycreek">Athey Creek MS</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/5330542161559552">Jackson MS</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/loj_drama">Lake Oswego Junior HS</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/lohsdrama">Lake Oswego HS</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/lakeridgejh">Lakeridge Junior HS</a>
                            &nbsp;| <a href="https://www.eticketexpress.com/host/wvhs">Wilsonville HS</a>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </Container>
    )
}