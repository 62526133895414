import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

import Home from './components/Home'
import Login from './components/Login'
import Host from './components/Host'
import HostRequest from './components/HostRequest'
import Register from './components/Register'
import Checkout from './components/Checkout'
import Receipt from './components/Receipt'
import StripeConnect from './components/StripeConnect'
import About from './components/About'
import Contact from './components/Contact'

import { onAuthStateChange, logout } from './components/util/firebase'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnchor, faSignInAlt, faInstitution } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'


function Header({ user }) {
    return (
        <Navbar expand='sm' className='navbar navbar-default navbar-header'
                style={{ paddingLeft: 20, paddingRight: 32 }}>
            <Navbar.Brand>
                <Link to='/' style={{ color: 'white', padding: '15px', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faCheckSquare} className='fa bg-light p-1'/> eRegister Express
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse className='justify-content-end'>
                <ButtonGroup size='sm' className='mr-3'>
                    { user ?
                        <a href='/manager'><Button className='nav-tab blue-gradient' style={{ textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faAnchor} className='fa' /> Manager
                        </Button></a>
                    :   <Link to='/host_request'>
                            <Button className='nav-tab blue-gradient'>
                                <FontAwesomeIcon icon={faInstitution} className='fa' /> Host Request
                            </Button>
                        </Link>
                    }
                    { user ?
                        <Button className='nav-tab blue-gradient' onClick={logout}>
                            <FontAwesomeIcon icon={faSignInAlt} className='fa' /> Logout
                        </Button>
                    :   <Link to='/login'>
                            <Button className='nav-tab blue-gradient'>
                                <FontAwesomeIcon icon={faSignInAlt} className='fa' /> Login
                            </Button>
                        </Link>
                    }
                </ButtonGroup>
            </Navbar.Collapse>
        </Navbar>
    )
}

function App() {
    const [user, setUser] = useState()
    useEffect(() => {
        const unsubscribe = onAuthStateChange(setUser)
        return unsubscribe
    }, [])
    const now = new Date()

    return (
        <Router>
            <Header user={user} />
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/host/:host_id' element={<Host/>} />
                <Route path='/host_request' element={<HostRequest/>} />
                <Route path='/register/:host_id/:event_id' element={<Register/>} />
                <Route path='/Checkout/:form_id' element={<Checkout/>} />
                <Route path='/Receipt/:form_id' element={<Receipt/>} />
                <Route path='/Stripe/Connect' element={<StripeConnect/>} />
                <Route path='/about/:page' element={<About/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='*' element={<Home/>} />
                {/* /login */}
                </Routes>
            <footer className='blue-gradient mt-3'>
                <Container style={{ padding: 15 }}>
                    <div className='text-center footer-links dont_print'>
                        <Link to='/about/us'>About Us</Link>
                        <Link to='/contact'>Contact Us</Link>
                        <Link to='/about/our_services'>Our Services</Link>
                        <Link to='/about/privacy'>Privacy Policy</Link>
                        <Link to='/about/terms'>Terms of Service</Link>
                    </div>
                    <div className='text-center small'>
                        ePayment Express, LLC &copy; 2010-{ now.getFullYear() }. All rights reserved.
                    </div>
                </Container>
            </footer>
        </Router>
    )
}

export default App
