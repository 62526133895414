export { getResource, postResource, putResource, deleteResource }

const RESOURCE = '/resource'

function getResource(uri) {
    return fetch(RESOURCE + uri).then(res => res.json())
}

function postResource(uri, body) {
    return fetch(RESOURCE + uri,
                {method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'same-origin',
                body: JSON.stringify(body)
                }).then(res => res.json())
}

function putResource(uri, body) {
    return fetch(RESOURCE + uri,
                {method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                credentials: 'same-origin',
                body: JSON.stringify(body)
                }).then(res => res.json())
}

function deleteResource(uri) {
    return fetch(RESOURCE + uri, {method: 'DELETE'}).then(res => res.json())
}
